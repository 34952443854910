import React from 'react';
import { createRoot } from 'react-dom/client';
import Ad from 'common-client/components/Ad';
import formatDate from 'common-client/utils/datetime';
import { t } from '@lingui/core/macro';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import i18nUtil from '../i18nUtil';

const el = document.querySelector('singleadpodlet-isolated')?.shadowRoot?.querySelector('#content-single-ad');
if (el) {
  const root = createRoot(el);
  const ad = window.SINGLEAD_PODLET_PROPS;
  const locale = el.getAttribute('data-locale') || 'no';
  if (Object.keys(ad).length > 0) {
    i18nUtil.loadLocale(locale);
    root.render(
      <I18nProvider i18n={i18n}>
        <Ad
          ad={ad}
          href=""
          showUpdated
          showExternalData={false}
          adTitleFallbackText={t({ comment: 'Title on ad when the ad does not have a title', message: 'Title missing' })}
          daysUntilExpireText={ad.daysUntilExpires && +ad.daysUntilExpires < 3650 ? t({
            comment: 'Amount of days left before display period expires',
            message: `${ad.daysUntilExpires} days left`,
          }) : null}
          lastUpdatedText={ad.updated ? t({
            comment: 'Last modified date',
            message: `Last modified: ${formatDate(ad.updated, 'do MMMM y', locale)}`,
          }) : null}
        />
      </I18nProvider>,
    );
  }
}
