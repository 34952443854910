import { format, Locale } from 'date-fns';
import {
  enGB, nb, fi, da,
} from 'date-fns/locale';

const locales: { [key: string]: Locale } = {
  en: enGB, nb, fi, da,
};

export default function formatDate(
  dateString: string,
  dateFormat: string,
  localeId: string,
) {
  return format(new Date(dateString), dateFormat, {
    locale: locales[localeId],
  });
}
