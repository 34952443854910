import React from 'react';
import { Badge } from '@warp-ds/react';

function getVariant(display: string) {
  switch (display) {
    case 'disabled':
    case 'warning':
      return 'disabled';
    case 'success':
      return 'positive';
    case 'error':
      return 'negative';
    default:
      return 'neutral';
  }
}

interface AdStatusPillProps {
  display: string;
  label: string;
}

export default function AdStatusPill({
  display,
  label,
}: AdStatusPillProps) {
  return (
    <Badge variant={getVariant(display)}>{label}</Badge>
  );
}
