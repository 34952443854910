import { i18n } from '@lingui/core';
import { messages as enMessages } from './locales/en/messages';
import { messages as nbMessages } from './locales/nb/messages';
import { messages as fiMessages } from './locales/fi/messages';
import { messages as daMessages } from './locales/da/messages';

export default {
  loadLocale: (locale: string) => {
    i18n.load({
      en: enMessages,
      nb: nbMessages,
      fi: fiMessages,
      da: daMessages,
    });
    i18n.activate(locale);
  },
};
